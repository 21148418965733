* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a:focus, button:focus,
a:hover, button:hover,
a:active, button:active {
    outline: 0;
    border:none;
    -moz-outline-style: none;
}

/* stop the ugly yellow autofill - make it white */
input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
    -webkit-box-shadow: 0 0 0 30px white inset;
}

body {
    font-size: 18px;
    font-weight: 300;
}

.row {
    margin-bottom: 0;
}

.section-padding {
    padding-top: 15px;
    padding-bottom: 15px;
    @media #{$medium-and-up} {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    &.no-bottom-pad {
        padding-bottom: 0;
    }
}

.carousel-item {
    padding: 15px;
    @media #{$medium-and-up} {
        padding: 30px;
    }
}

.section-flex {
    .row {
        display: flex;
        flex-wrap: wrap;
    }
}

.background-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    &.home-customer-champ {
        @media #{$small-and-down} {
            background-position-x: -1390px;
            background-blend-mode: darken;
        }
    }
}

.dsp-mobile-container {
    @media #{$medium-and-up} {
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),
        0 3px 1px -2px rgba(0,0,0,0.12),
        0 1px 5px 0 rgba(0,0,0,0.2);
        padding-bottom: 0px !important;
    }
}

.qq-and-contact-border {
    min-width: 280px;
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
}

.qq-and-contact-border-no-left-margin {
    min-width: 280px;
    max-width: 420px;
    margin-left: 0;
    margin-right: auto;
}

.btn-content {
    text-transform: none;
}

.fixed-bg {
    background-attachment: fixed;
}

.left-bg {
    background-position-x: left;
}

.right-bg {
    background-position-x: right;
}

.center-bg {
    background-position-y: center;
}

.bottom-bg {
    background-position-y: bottom;
}

@media #{$extra-large-and-up} {
    .dl-container-restrict {
        width: 1170px;
        margin: 0 auto;
    }
}

.center-block {
    margin-left: auto;
    margin-right: auto;
}

.section-content {
    ul:not(.collapsible) {
        padding-left: 25px;
        list-style-type: disc;

        & > li {
            list-style-type: disc;
        }
    }
}


@media #{$medium-and-up} {

    .desktop-gutter {
        padding-right: 50px;
        padding-left: 50px;
    }

    .blog-list {

        &.row .col {
            padding: 0 15px;
        }

        .card {
            margin-bottom: 25px;
        }

    }

}

.primary-text {
    color: $primary-color;
}

.alternate-text {
    color: $alternate-color;
}

@media #{$small-and-down} {
    th.table-header {
        vertical-align: bottom;
    }

}

.blog-post-summary, .blog-post {
    .category-list {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 10px;

        .category {
            color: #ececec;
            border: 1px solid;
            padding: 0 5px;
            font-size: 14px;
            font-weight: 400;
        }
    }
}

.dig-landing-header {
    height: 300px;
    h1 {
        color: $primary-color;
        margin-left: 20px;
    }
    @media #{$medium-and-up} {
        height: 400px;
    }
}

.dig-landing-detail {
    margin-top: -60px;

    .profile-pic {
        z-index: 1;
        position: absolute;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        margin-top: -8px;
        margin-left: -8px;
        @media #{$medium-and-up} {
            height: 100px;
            width: 100px;
            margin-top: -30px;
            margin-left: -40px;
        }
    }

    .collection-item .fa {
        min-width: 20px;
        text-align: center;
    }

    .collection-item .fa.fa-envelope-o {
        font-size: 12px;
    }

}

td, th {
    border-radius: 0;
}

.embed-responsive {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.form-text {
    font-size: 75%;
}

.disclosure-stop-message {
    color: $error-color;
    margin-bottom: 10px !important;
}
