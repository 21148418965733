nav {
    transition: all 0.25s ease;
    background-color: $navbar-bgcolor ;

    ul a {
        font-size: 18px;
    }

}

.trans-head {
    header {
        margin-bottom: -$navbar-height;
    }

    &.top {
        header {
            margin-bottom: -$navbar-height;

            nav {
                background-color: transparent;
                box-shadow: none;
                ul a {
                    color: white;
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.2);
                    }
                }
            }

        }
    }
}

@media #{$medium-and-down} {
    .navbar-fixed nav {
        position: absolute;
    }
}

// offset the anchor bookmark for the fixed header
:target::before {
    content: "";
    display: block;
    height: $navbar-height; /* fixed header height*/
    margin: -$navbar-height 0 0; /* negative fixed header height */
}
