@charset "UTF-8";

// Materialize Base
@import "../../../../../node_modules/materialize-css/sass/components/color-variables";
@import "../../../../../node_modules/materialize-css/sass/components/color-classes";
@import "../../../../../node_modules/materialize-css/sass/components/normalize";
// Colors
$primary-color: #00a69c;
$alternate-color: #E7922D;
$primary-color-light: lighten($primary-color, 15%);
$primary-color-dark: darken($primary-color, 15%);
$secondary-color: color("grey", "lighten-4");
$success-color: color("green", "base");
$error-color: color("red", "base");
$link-color: $primary-color;
$dl-grey: #d1d4d3;
$dl-light-grey: #eaeaea;
@import "../../../../../scss/components/materialize-variables";
// Font
$font-stack: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$h1-fontsize: 38px;
$h2-fontsize: 26px;
$h3-fontsize: 20px;
$h4-fontsize: 18px;
$h5-fontsize: 18px;
$h6-fontsize: 18px;
// Nav
$navbar-height: 65px;
$navbar-line-height: $navbar-height;
$navbar-height-mobile: $navbar-height;
$navbar-line-height-mobile: $navbar-height;
$navbar-font-color: $link-color;

$button-raised-color: color("grey", "darken-4");

// Components
@import "../../../../../scss/components/materialize";
@import "../../../../../scss/components/general";
$fa-font-path: "//netdna.bootstrapcdn.com/font-awesome/4.7.0/fonts";
@import "../../../../../scss/components/nav";

h2, h3, h4, h5, h6 {
    font-weight: 300;
}

#white_logo {
    display: none;
    width: 180px;
    margin-top: 20px;
    margin-left: 18px;
}

#normal_logo {
    width: 180px;
    margin-top: 20px;
    margin-left: 18px;
}

#mobile-menu {
    position: absolute;
    width: 100%;
    z-index: 1000;
    .menu {
        background-color: #fff;
        li {
            font-size: 18px;
            text-align: center;
        }
    }
}
